import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space reltTopics" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "related_topic_outer" }
const _hoisted_4 = {
  key: 0,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_topic_card_primary = _resolveComponent("topic-card-primary")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_alphabet_pagination = _resolveComponent("alphabet-pagination")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "cmn_heading_area text-center" }, [
        _createElementVNode("h2", { class: "heading sairaR mb-5" }, [
          _createTextVNode(" Related "),
          _createElementVNode("span", { class: "cm-color" }, "Topics")
        ]),
        _createElementVNode("span", { class: "headUnderlne" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.topics)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topics, (topic) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-sm-6 col-lg-4",
                  key: topic.id
                }, [
                  _createVNode(_component_topic_card_primary, {
                    description: topic.description,
                    image: topic.image,
                    title: topic.title,
                    level: _ctx.topicLevel,
                    onOnClick: ($event: any) => (_ctx.onClickTopic(topic.slug))
                  }, null, 8, ["description", "image", "title", "level", "onOnClick"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_pagination, {
        currentPage: _ctx.currentPage,
        totalPages: _ctx.totalPages,
        onOnChangePage: _ctx.setPage
      }, null, 8, ["currentPage", "totalPages", "onOnChangePage"]),
      (_ctx.showAlphabet)
        ? (_openBlock(), _createBlock(_component_alphabet_pagination, {
            key: 0,
            selectedLetter: _ctx.selectedLetter,
            onOnClick: _ctx.onToggleLetter
          }, null, 8, ["selectedLetter", "onOnClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}