import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/right-arrow-black.svg'


const _hoisted_1 = { class: "cmn_card_wrapper" }
const _hoisted_2 = { class: "topic_card" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "topic_content" }
const _hoisted_5 = {
  class: "mb-2 cm-color d-block",
  style: {"cursor":"pointer"}
}
const _hoisted_6 = { class: "topic_excrept" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("figure", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick')))
      }, [
        _createElementVNode("img", {
          src: _ctx.image,
          alt: ""
        }, null, 8, _hoisted_3),
        _createElementVNode("span", {
          class: _normalizeClass(["badge", _ctx.topicLevelClass])
        }, _toDisplayString(_ctx.levelLabel), 3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClick')))
        }, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1),
        _createElementVNode("button", {
          class: "cm_bg cm-btn",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onClick')))
        }, _cache[3] || (_cache[3] = [
          _createTextVNode(" Explore "),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "ml-1"
          }, null, -1)
        ]))
      ])
    ])
  ]))
}